<template>
<div style="position: relative; min-height: 50px">
  <div v-if="data">
    <calendar-heatmap style="max-width: 900px"
      :values="data.days"
    />
  </div>
  <b-loading :is-full-page="false" :active="!data && !noData" :can-cancel="true"></b-loading>
</div>
</template>

<script>
import CalendarHeatmap from './CalendarHeatmap.vue';

export default {
  name: 'device-statistics',
  components: { CalendarHeatmap },
  props: {
    device_id: {
      required: true,
    },
    cache: {},
  },
  data() {
    return {
      data: (this.$props.cache && this.$props.cache.statistics),
      noData: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (!this.device_id || this.data) return;
      const { data } = await this.$http.get(`/v1/device/${this.device_id}/statistics`);
      if (data === '') {
        this.noData = true;
        return;
      }
      this.data = data;
      if (this.$props.cache) {
        this.$props.cache.statistics = data;
      }
    },
  },
};
</script>
