const DAYS_IN_ONE_YEAR = 365;
const DAYS_IN_WEEK = 7;

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function parseDate(entry) {
  return (entry instanceof Date) ? entry : (new Date(entry));
}

function keyDayParser(date) {
  const day = parseDate(date);
  return `${day.getFullYear()}-${day.getMonth()}-${day.getDate()}`;
}

export default class CalendarHeatmap {
  constructor(endDate, values, max) {
    this.endDate = endDate ? parseDate(endDate) : new Date();
    this.max = max || Math.ceil((Math.max(...values.map((day) => day.count)) / 5) * 4);
    this.startDate = shiftDate(this.endDate, -DAYS_IN_ONE_YEAR);
    this.values = values;
  }

  get activities() {
    return this.values.reduce((value, day) => {
      const newValues = value;
      newValues[keyDayParser(day.date)] = {
        count: day.count,
        colorIndex: this.getColorIndex(day.count),
      };
      return newValues;
    }, {});
  }

  get weekCount() {
    return this.getDaysCount() / DAYS_IN_WEEK;
  }

  get calendar() {
    const date = shiftDate(this.startDate, -this.getCountEmptyDaysAtStart());
    return Array.from({ length: this.weekCount },
      () => Array.from({ length: DAYS_IN_WEEK },
        () => {
          const dDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          const dayValues = this.activities[keyDayParser(dDate)];
          date.setDate(date.getDate() + 1);
          return {
            date: dDate,
            count: dayValues ? dayValues.count : null,
            colorIndex: dayValues ? dayValues.colorIndex : 0,
          };
        }));
  }

  get firstFullWeekOfMonths() {
    return this.calendar.reduce((months, week, index, weeks) => {
      if (index > 0) {
        const lastWeek = weeks[index - 1][0].date;
        const currentWeek = week[0].date;
        if (lastWeek.getFullYear() < currentWeek.getFullYear() || lastWeek.getMonth() < currentWeek.getMonth()) {
          months.push({ value: currentWeek.getMonth(), index });
        }
      }
      return months;
    }, []);
  }

  getColorIndex(value) {
    if (value == null || value === undefined) {
      return 0;
    } if (value <= 0) {
      return 1;
    } if (value >= this.max) {
      return 5;
    }
    return (Math.ceil(((value * 100) / this.max) * (0.03))) + 1;
  }

  getCountEmptyDaysAtStart() {
    return this.startDate.getDay();
  }

  getCountEmptyDaysAtEnd() {
    return (DAYS_IN_WEEK - 1) - this.endDate.getDay();
  }

  getDaysCount() {
    return DAYS_IN_ONE_YEAR + 1 + this.getCountEmptyDaysAtStart() + this.getCountEmptyDaysAtEnd();
  }
}
